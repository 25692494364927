import React from "react";
import { BulmaButton } from "../generic/Buttons/BulmaButton";
import { CHECKBOX_ROWS_PER_COLUMN } from "./constants";

export const FieldVisibilityTable = ({
  handleClick,
  update,
  headers,
  checkboxes,
}) => {
  const keys = Object.keys(checkboxes);
  const table = [];

  for (let i = 0; i < CHECKBOX_ROWS_PER_COLUMN; i++) {
    let row = [];
    for (let j = 0; j < Math.ceil(keys.length / CHECKBOX_ROWS_PER_COLUMN); j++) {
      const h = headers[j * CHECKBOX_ROWS_PER_COLUMN + i];
      row.push(
        <td key={`${i}-${j}`}>
          {h ? (
            <label>
              <input
                name={h.accessor}
                type="checkbox"
                checked={checkboxes[h.accessor]}
                onChange={handleClick}
              />
              &nbsp;{h.title}
            </label>
          ) : (
            ""
          )}
        </td>
      );
    }
    table.push(<tr key={i}>{row}</tr>);
  }

  return (
    <div>
      <table>
        <tbody>
          {table}
        </tbody>
      </table>
      <br />
      <div>
        <BulmaButton customClass="is-light" onClick={update}>
          Save Configuration
        </BulmaButton>
      </div>
    </div>
  );
};
