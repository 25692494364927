import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUploadURL, setUploadStatus} from "../../redux/Recipients/actions";
import { BulmaButton } from "../generic/Buttons/BulmaButton";

class RecipientUploadOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opt_out: true,
      merge: true,
      misc_update: false
    };
  }

  submit = (e) => {
    const {
      loading,
      directUploadingInProgress,
      selectedCampaignId,
      getUploadURL,
      userPermissions
    } = this.props;
    if (
      this.state.filename === undefined ||
      loading ||
      directUploadingInProgress
    ) {
      return;
    }

    getUploadURL(
      selectedCampaignId,
      this.file.files[0],
      this.state.merge,
      this.state.opt_out,
      this.state.misc_update,
      this.state.filename.includes(".gz"),
      userPermissions,
    );
  };

  /**
   * Don't use with non checkbox values
   * @param {*} e
   */
  optionsChanged = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  fileChanged = (e) => {
    if (!e.target.files.length) {
      return;
    }
    if (this.props.status === "failed"){
      this.props.resetUploadStatus();
    }
    this.setState({
      filename: e.target.files[0].name,
    });
  };

  render() {
    const { loading, error, status, errorMessage, cancel, directUploadingInProgress } = this.props;
    const maxSizeInMb = process.env.REACT_APP_S160_RECIPIENT_FILESIZE_MAX_MB || 0;
    const unrestrictedUploads = this.props.userPermissions.includes("ADD_CAMPAIGN_RECIPIENTS_WITHOUT_LIMITS");
    return (
      <>
        <h4 className="title is-5">Upload New Recipients</h4>
        <div class="control">
          <input
            type="checkbox"
            name="opt_out"
            id="opt_out"
            checked={this.state.opt_out}
            onChange={this.optionsChanged}
          />
          <label htmlFor="opt_out" className="pl-2">
            Filter out recipients from opt-out list
          </label>
          </div>

          <div>
          <input
            type="checkbox"
            id="merge"
            name="merge"
            checked={this.state.merge}
            onChange={this.optionsChanged}
          />
          <label htmlFor="merge" className="pl-2">
            Merge with current Recipient List
          </label>
          </div>

          <input 
            type="checkbox"
            name="misc_update"
            checked={this.state.misc_update}
            onChange={this.optionsChanged}
          />
          &nbsp; Replace existing miscellaneous variables <br />
          
          {!unrestrictedUploads && maxSizeInMb > 0 && (
            <p className="mt-3">Uploaded files must be less than {maxSizeInMb}MB</p>
          )}
          <div className={`file mt-3 ${this.state.filename ? "has-name" : ""}`}>
            <label className="file-label">
              <input
                className="file-input"
                id="myFileNew"
                name="files[]"
                multiple=""
                type="file"
                accept=".csv,.gz"
                ref={(f) => (this.file = f)}
                onChange={this.fileChanged}
              />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">Choose a file…</span>
              </span>
              {this.state.filename && (
                <span class="file-name">{this.state.filename}</span>
              )}
            </label>
          </div>
          {status === "failed" && (
            <p className="has-text-danger">{errorMessage}</p>
          )}
        {/* TODO: make this disabled before we select a file */}
        <BulmaButton
          customClass="is-success"
          onClick={this.submit}
          loading={loading || directUploadingInProgress}
          error={error}
          disabled={this.state.filename === undefined || loading || directUploadingInProgress || status === "failed"}
        >
          Process list for upload
        </BulmaButton>

        <BulmaButton customClass="is-light" onClick={cancel}>
          Cancel
        </BulmaButton>

        <br />
      </>
    );
  }
}

RecipientUploadOptions.propTypes = {
  change: PropTypes.func,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUploadURL: (campaignid, filename, merge, opt_out, misc_update, isGz, userPermissions) =>
        getUploadURL(campaignid, filename, merge, opt_out, misc_update, isGz, userPermissions),
      resetUploadStatus: () => dispatch(setUploadStatus(undefined))
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(RecipientUploadOptions);