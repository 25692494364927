import React, { Component } from "react";

import ModalContainer, {
  ModalBody,
  ModalHeader,
  ModalFooter
} from "./ModalContainer";

import store from "../../store"

class ConfirmActionModal extends Component {
  constructor(props) {
    super(props);

    this.state = { loading: false, inProgress: false };

    this.actionWrapper = this.actionWrapper.bind(this);
    this.promiseWrapper = this.promiseWrapper.bind(this);
    this.resetProgress = this.resetProgress.bind(this);
  }

  actionWrapper(e) {
    if (this.state.loading || this.props.loading) {
      return;
    }

    this.props.actionCallback();

    const newState = {
      inProgress: true
    }

    if (this.props.loading === undefined) {
      newState.loading = true;
    }

    this.setState(newState)
  }

  promiseWrapper(conditionCallback, timeoutMS, intervalMS) {
    let timeout = timeoutMS;
    return new Promise((resolve, reject) => {
      const checkCondition = () => {
        console.log("in the promise", conditionCallback());
        if (conditionCallback()) {
          resolve();
        } else if ((timeout -= intervalMS) <= 0) {
          reject();
        } else {
          setTimeout(checkCondition, intervalMS);
        }
      };

      setTimeout(checkCondition, intervalMS);
    });
  }

  resetProgress() {
    this.setState({
      inProgress: false,
      loading: false
    })
  }

  render() {
    const { buttonClass, itemName, actionName, loading, message, closeCallback, visibleButtonOverride = null, title, disabled = false } = this.props;
    const name = actionName[0].toUpperCase() + actionName.slice(1);

    // If there is a loading prop use that.
    const actualLoading = loading === undefined ? this.state.loading : loading;

    // If the loading is finished and inProgress is true then the action is complete
    const done = !loading && this.state.inProgress;

    let messageValue = message;

    if (itemName) {
      messageValue = <>Are you sure you want to {actionName} <b>{itemName}</b>?</>
    }
    
    if (message) {
      messageValue = message;
    }
    
    if (done) {
      messageValue = <b>All Done!</b>;
    }

    return (
      <ModalContainer disabled={disabled} name={visibleButtonOverride || name} customButtonClass={buttonClass} title={title}>
        {modalState => (
          <React.Fragment>
            <ModalHeader>{name}</ModalHeader>
            <ModalBody>
              {messageValue}
            </ModalBody>
            <ModalFooter
              {...modalState}

              toggleOpen={() => {
                this.resetProgress();
                modalState.toggleOpen();
                if (closeCallback) { closeCallback(); }
              }}

              customText={done ? "Close" : "Cancel"}
            >
              {!done ? <button
                className={`button is-success ${actualLoading ? "is-loading" : ""}`}
                onClick={this.actionWrapper}
              >
                {name}
              </button> : null}
            </ModalFooter>
          </React.Fragment>
        )}
      </ModalContainer>
    );
  }
}

export default ConfirmActionModal;
