import { removeCookieList } from "../../util/helper";
import { fetchRequest } from "../../middleware/fetchMiddleware";
import { push } from "connected-react-router";

export const AUTH_IS_LOADING = "AUTH_IS_LOADING";
export const AUTH_HAS_ERROR = "AUTH_HAS_ERROR";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export function authIsLoading(isLoading) {
  return (dispatch) => {
    dispatch({
      type: AUTH_IS_LOADING,
      isLoading: isLoading,
    });
  };
}

export function authHasError(hasError, message) {
  return (dispatch) => {
    dispatch({
      type: AUTH_HAS_ERROR,
      hasError: hasError,
      errorMessage: message,
    });
  };
}

export function authSuccess(data) {
  return (dispatch) => {
    dispatch({
      type: AUTH_SUCCESS,
      jwt: data.jwt,
      userid: data.userid,
      permissionlevel: data.permissionlevel,
    });
  };
}

/**
 * Update the JWT token in the store
 * (Note that this doesn't follow the same [ACTION_NAME}_SUCCESS pattern as the other actions)
 */
export const UPDATE_JWT_TOKEN = "UPDATE_JWT_TOKEN";
export const updateJWTToken = (refreshToken) => ({
  type: UPDATE_JWT_TOKEN,
  refreshToken
});

/**
 * Log in as a regular user (non authy)
 * @param {string} userid 
 * @param {string} password 
 * @returns 
 */
export function authPost(userid, phone, email, password) {
  return (dispatch) => {
    dispatch(authIsLoading(true));

    const params = {
      userid,
      phone,
      email,
      claimedpassword: password,
    };

    // We should clear data before pass loging, but not token
    dispatch({ type: "CLEAR_DATA" });

    return dispatch(fetchRequest("LOGIN", "POST", "/authenticateuser", params))
      .catch((error) => {
        dispatch(authHasError(true, error.message));
        console.error(error.message);
      });
  };
}

export function verifyToken(email, userid, token) {
  return (dispatch) => {
    dispatch(
      fetchRequest("VERIFY_TOKEN", "POST", "/verifyToken", {
        email, userid, token,
      })
    ).catch((error) => {
      console.error(error.message);
    });
  };
}

export function sendSMSToken(email, userid) {
  return (dispatch) =>
    dispatch(
      fetchRequest("SEND_SMS_TOKEN", "POST", "/sendSMSToken", {
        userid, email
      })
    );
}

/**
 * Register a new user with Authy
 * @param {email} email The user's email address
 * @param {int} phone The user's phone number
 * @returns 
 */
export function registerAuthyUser(email, phone) {
  return (dispatch) => {
    dispatch({ type: "CLEAR_DATA" });
    return dispatch(fetchRequest("REGISTER_AUTHY_USER", "POST", "/registerUser", {email, phone}))
      .catch((error) => {
        dispatch(authHasError(true, error.message));
        console.error(error.message);
      });
  };
}

/**
 * Log user out
 * @returns 
 */
export function logOutPost() {
  return (dispatch, getState) => {
    return dispatch(fetchRequest("LOGOUT", "POST", "/logout", {}))
      .then(() => {
        removeCookieList(["jwt", "userid", "permission"]);
        dispatch(push("/authy-login"));
      })
      .catch((error) => {
        console.log(error);
        dispatch(authHasError(true, error));

        throw Error(error);
      });
  };
}

/**
 * Change the password with a new one
 * @param {string} oldPassword current password for verification
 * @param {string} newPassword new password to change to 
 * @returns 
 */
export function changePasswordPost(oldPassword, newPassword) {
  return (dispatch, getState) => {
    const params = {
      userid: getState().auth.userid,
      claimedpassword: oldPassword,
      newpassword: newPassword,
    };

    return dispatch(fetchRequest("CHANGE_PASSWORD", "POST", "/changepassword", params))
      .catch((error) => {
        console.log(error);
        dispatch(authHasError(true, error));

        throw Error(error);
      });
  };
}
