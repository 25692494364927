import React, { Component } from "react";
// import "bulma/css/bulma.css";

/**
 * A generic list component that uses Bulma columns and displays items
 *
 *  TODO: Ideally the list would work like this:
 *    <List
 *      headers={ ["name", "age", {"controls" : <Buttons>}]}
 *      list={[{name: "james", age: 11, controls: ["edit", "delete"]]}
 *    />
 *
 *  ALSO TODO: allow for summation rows
 *
 * Props:
 * list, {array} The list of items to display. Each item should be an object with keys to match the columns
 * headers, {array} List of headers to display
 */

/**
 * @typedef {Object} ListHeader
 * @property {string} header - The header text to display
 * @property {string} accessor - The key in the item object to display
 */

/**
  * A generic list component that uses Bulma columns and displays items
  * @prop {array} list - The list of items to display. Each item should be an object with keys to match the columns
  * @prop {ListHeader[]} headers - List of headers to display
  * @prop {boolean} flip - Whether to flip the table
  * @prop {string} id - Optional id for the table
  * @prop {string} className - Optional class name for the table
  * @prop {function} sort - Optional sort function for the table
  */

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  setSortFunction = (fnc) => {
    this.setState({
      sortFnc: fnc,
    });
  };

  render() {
    const { list, headers, flip, id = null, className = '', isNarrow = true} = this.props;
    const { sortBy } = this.state;

    if (this.state.sortFnc) {
      list.sort(this.state.sortFnc);
    }
    return (
      <table
        className={`table is-bordered is-striped ${isNarrow? "is-narrow" : ""} is-hoverable is-fullwidth ${className}`}
        style={{
          display: "block",
          height: "100%",
          overflow: "auto",
        }}
        id={id}
      >
        {/* <thead> */}
        {/* </thead> */}
        <tbody>
          {/* Headers */}
          <tr key={"headers "} className={flip ? "flip-table" : ""}>
            {headers.map((item, index) => {
              return (
                <th key={index + "-a"}>
                  {(item.sort || item.onClick) ? (
                    <a onClick={item.sort ? () => this.setSortFunction(item.sort) : item.onClick}>
                      {item.header}
                    </a>
                  ) : (
                    item.header
                  )}
                </th>
              );
            })}
          </tr>
          {/* List */}
          {list.length > 0 &&
            list
              // .sort(this.sort)
              .map((item, index) => {
                return (
                  <tr key={index} className={flip && "flip-table"}>
                    {headers.map((header) => {
                      return (
                        <td key={index + "-" + header.header}>
                          {item[header.accessor] !== "" &&
                          item[header.accessor] !== undefined
                            ? item[header.accessor]
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
        </tbody>
      </table>
    );
  }
}

export default List;
