import React, { Component } from "react";
import { NavLink, Route, Redirect, Switch } from "react-router-dom";

import AgentsContainer from "../AgentsTab/AgentsContainer";
import RecipientsPage from "../RecipientsTab/RecipientsPage";
import Tools from "../Tools";
import Script from "../Script";
import Prompt from "../Prompt";
import Analytics from "../AnalyticsTab/Analytics";
import BillingContainer from "../BillingTab/BillingContainer";
import QuotasContainer from "../Quotas/QuotasContainer";
import { SystemAdminTools } from "../SystemAdminTools";
import Warnings from "../Warnings/Warnings";
import WarningsContainer from "../Warnings/WarningsContainer";
import { FilteredComponentList } from "../generic/FilteredComponentList";
import UploadProcessingResultsWrapper from "../RecipientsTab/UploadProcessingResultsWrapper";
import {
  LoaderAndErrorPrompt,
} from "../higher-order/ComponentWithLoadingAndError";

const MAIN_PATH = "main";
const ACTIVE_CLASS_NAME = "is-active";

/**
 * The main screen is really the main container. It contains the horizontal
 * campaign name, the selector below this, and the data that's presente.
 * The main screen receives the following props, most of which are passed on.
 */
function CampaignScreen({
  deleteCampaign,
  updatePrompt,
  updateList,
  updateScript,
  enterSandboxMode,
  exitSandboxMode,
  getRandomListEntries,
  error,
  loading,
  permission,
  selectedCampaignData,
  selectedCampaignId,
  campaigns,
  vitalsData,
  auth,
  location,
  history,
  setCampaignDataUpdated,
  bwSettings,
  assignBandwidthRegistrationToCampaign,
  campaignHeaders,
  tools,
}) {

  const basePath = "/dashboard/campaigns/:id";
  let fields = [];
  if (Object.keys(selectedCampaignData.list).length)
    fields = Object.keys(
      selectedCampaignData.list[Object.keys(selectedCampaignData.list)[0]].misc
    );
  
  // Special case: if the campaign is archived, only show the main tab
  if (selectedCampaignData.active === "archived") {
    campaignHeaders = campaignHeaders.slice(0,1)
  }

  return (
    <div className="tile is-ancestor is-vertical">
      <CSTabs menuItems={campaignHeaders} location={location} />
      <LoaderAndErrorPrompt>
        {selectedCampaignData && (
          <Switch>
            <Redirect exact from={`${basePath}`} to={MAIN_PATH} />
            <FilteredComponentList
              includeValuesList={campaignHeaders.map(
                (item) => `${basePath}/${item.toLocaleLowerCase()}`
              )}
              filterProp="path"
            >
              <Route
                path={`${basePath}/script`}
                render={() => {
                  return (
                    <Script
                      active={selectedCampaignData.active}
                      list={selectedCampaignData.list}
                      script={selectedCampaignData.script}
                      updateScript={updateScript}
                      subject={"Script"}
                      selectedCampaignData={selectedCampaignData}
                      selectedCampaignIndex={selectedCampaignId}
                      getRandomListEntries={getRandomListEntries}
                      showPanelQuestions={tools.includes("EDIT_SCRIPT_PANEL")}
                    />
                  );
                }}
              />
              <Route
                path={`${basePath}/prompts`}
                render={() => {
                  return (
                    <Prompt
                      active={selectedCampaignData.active}
                      list={selectedCampaignData.list}
                      prompt={selectedCampaignData.prompt}
                      updatePrompt={updatePrompt}
                      subject={"Prompt"}
                      selectedCampaignData={selectedCampaignData}
                      selectedCampaignIndex={selectedCampaignId}
                      getRandomListEntries={getRandomListEntries}
                    />
                  );
                }}
              />
              <Route
                path={`${basePath}/agents`}
                render={() => {
                  return (
                    <AgentsContainer
                      initParams={[selectedCampaignId]}
                      active={selectedCampaignData.active}
                      login={selectedCampaignData.login}
                      selectedCampaignId={selectedCampaignId}
                      selectedCampaignData={selectedCampaignData}
                    />
                  );
                }}
              />
              <Route
                path={`${basePath}/quotas`}
                render={() => {
                  return (
                    <QuotasContainer
                      // TODO: will eventually need the variables from the list.
                      selectedCampaignId={selectedCampaignId}
                      fields={fields}
                      userPermissions={tools}
                    />
                  );
                }}
              />
              <Route
                path={`${basePath}/recipients`}
                render={() => {
                  return (
                    <UploadProcessingResultsWrapper
                      initParams={[selectedCampaignId]}
                    >
                      <RecipientsPage
                        selectedCampaignData={selectedCampaignData}
                        selectedCampaignId={selectedCampaignId}
                        updateList={updateList}
                        setCampaignDataUpdated={setCampaignDataUpdated}
                        userPermissions={tools}
                      />
                    </UploadProcessingResultsWrapper>
                  );
                }}
              />
              <Route
                path={`${basePath}/analytics`}
                render={() => {
                  return (
                    <Analytics
                      active={selectedCampaignData.active}
                      tabs={[
                        { name: "campaigns", dataName: "campaign" },
                        { name: "users", dataName: "user" },
                      ]}
                      analyticscheckboxconfig={
                        vitalsData.analyticscheckboxconfig
                      }
                      selectedOrganizationId={
                        campaigns.find(
                          (c) => c.campaignid === selectedCampaignId
                        ).organizationid
                      }
                      selectedCampaignId={selectedCampaignId}
                      scope="campaign"
                    />
                  );
                }}
              />
              <Route
                path={`${basePath}/billing`}
                render={() => {
                  return (
                    <BillingContainer 
                      userPermissions={tools}
                      selectedCampaignData={selectedCampaignData}
                    />
                  );
                }}
              />
              <Route
                path={`${basePath}/settings`}
                render={() => {
                  return (
                    <SystemAdminTools
                      selectedCampaignData={selectedCampaignData}
                      userPermissions={tools}
                    />
                  );
                }}
              />
              <Route
                path={`${basePath}/notifications`}
                render={() => {
                  return (
                    <WarningsContainer
                      initParams={[selectedCampaignId]}
                      campaignid={selectedCampaignId}
                    >
                      <Warnings />
                    </WarningsContainer>
                  );
                }}
              />
              <Route
                path={`${basePath}/main`}
                render={() => {
                  return (
                    <Tools
                      selectedCampaignId={selectedCampaignId}
                      selectedCampaignData={selectedCampaignData}
                      deleteCampaign={deleteCampaign}
                      enterSandboxMode={enterSandboxMode}
                      exitSandboxMode={exitSandboxMode}
                      campaigns={campaigns}
                      history={history}
                      vitals={vitalsData}
                      auth={auth}
                    />
                  );
                }}
              />
            </FilteredComponentList>
          </Switch>
        )}
      </LoaderAndErrorPrompt>
    </div>
  );
}

export default CampaignScreen;

/**
 * @typedef CSTabsProps
 * @type {object}
 * @param {Array<string>} menuItems
 * @param {object} location
 */

/**
 *
 * @param {CSTabsProps} props
 * @returns {JSX.Element}
 */
function CSTabs({ menuItems, location }) {
  return (
    <div className="tabs">
      <ul>
        {menuItems.map((value, index) => {
          const lcValue = value.toLocaleLowerCase();

          let liClass = "";
          if (location.pathname.endsWith(lcValue)) {
            liClass = ACTIVE_CLASS_NAME;
          }

          return (
            <li className={liClass} key={index}>
              <NavLink activeClassName={ACTIVE_CLASS_NAME} to={lcValue}>
                {value}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
